import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class FileApiService {
  constructor(private http: HttpService) {}

  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/file' + path, queryMap);
  }

  upload(file: any) {
    const url = this.buildurl('/');
    const form = new FormData();
    form.set('file', file);
    return this.http.post(url, form);
  }

  fileurl(id: string) {
    if (id.startsWith('http')) {
      return id;
    }
    return environment.api.s3endpoint + `/${id}`;
  }
}
